<template>
  <div class="memorial-profile-creation-page">
    <v-breadcrumbs :items="['Profil', 'Edycja profilu pamięci']"></v-breadcrumbs>

    <h1 class="steps-header">Edycja profilu pamięci</h1>

    <MemProfCreationStep1
        v-if="currentStep === 1"
    ></MemProfCreationStep1>
    <MemProfCreationStep2
        v-if="currentStep === 2"
        @update:is-form-valid="setIsFormValid"
        @profile-updated="save"
    ></MemProfCreationStep2>
    <MemProfCreationStep3
        v-if="currentStep === 3"
        @profile-updated="save"
    ></MemProfCreationStep3>

    <div class="steps-footer">
      <v-btn
          v-if="currentStep !== 3"
          :disabled="isNextButtonDisabled"
          class="step-button"
          type="submit"
          @click="goToNextStep"
      >
        <span>Przejdź dalej</span>
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>

      <v-btn
          v-if="currentStep === 3"
          class="step-button"
          type="submit"
          @click="saveAndGoToPreview"
      >
        <span>Podgląd profilu</span>
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>

      <v-btn v-if="currentStep !== 2" class="step-button outline" @click="goToPreviousStep">
        <v-icon>mdi-arrow-left</v-icon>
        <span>Poprzedni krok</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import MemProfCreationStep1 from './MemProfCreationStep1.vue';
import MemProfCreationStep2 from './MemProfCreationStep2.vue';
import MemProfCreationStep3 from './MemProfCreationStep3.vue';
import { useMemorialProfileStore } from '@/stores/memorial-profile';
import { mapActions, mapState } from "pinia";
import {useAuthStore} from "@/stores/auth";
import axiosInstance from "@/interceptors/axios.config";

export default {
  components: {
    MemProfCreationStep1,
    MemProfCreationStep2,
    MemProfCreationStep3,
  },
  data() {
    const authStore = useAuthStore();

    return {
      isFormValid: true,
      isNextButtonDisabled: false,
      apiUrl: process.env.VUE_APP_API_URL,
      currentStep: 1,
      authStore,
    };
  },
  computed: {
    ...mapState(useMemorialProfileStore, ['memorialProfile'])
  },
  mounted() {
    document.addEventListener('keyup', this.handleKeyUp);
    this.initProfileData();
  },
  unmounted() {
    document.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    ...mapActions(useMemorialProfileStore, [
      'updateProfileState',
      'addSectionState',
      'removeSectionState',
      'addSectionItemState',
      'removeSectionItemState',
      'updateSectionItemState'
    ]),
    initProfileData() {
      this.slotNumber = this.$route.params.slotNumber;

      if (this.slotNumber) {
        this.fetchProfileData();
      } else {
        this.updateProfileState({ slot_number: '' });
      }
    },
    handleKeyUp(event) {
      if (event.key === 'Enter') {
        if (this.currentStep !== 3) {
          this.goToNextStep();
        } else {
          this.saveAndGoToPreview();
        }
      }
    },
    setIsFormValid(value) {
      this.isFormValid = value;
      this.setIsNextButtonDisabled(value);
    },
    fetchProfileData() {
      if (!this.slotNumber) {
        return;
      }

      axiosInstance.get(`${this.apiUrl}mem-profile/${this.slotNumber}`, {
        headers: {
          'Authorization': `Bearer ${this.authStore.userToken}`
        }
      }).then((response) => {
        if (response?.data) {
          this.updateProfileState(response.data);
          this.goToNextStep();
          this.saveSlotNumberToSessionStorage();
        }
      });
    },
    saveSlotNumberToSessionStorage() {
      if (this.memorialProfile.slot_number) {
        sessionStorage.setItem('slotNumber', this.memorialProfile.slot_number);
      }
    },
    setIsNextButtonDisabled(isFormValid) {
      this.isNextButtonDisabled = this.currentStep === 2 && !isFormValid;
    },
    goToPreviousStep() {
      if (this.currentStep === 1) {
        return;
      }

      this.currentStep = this.currentStep - 1;
      this.setIsNextButtonDisabled(this.isFormValid);
    },
    goToNextStep() {
      this.currentStep = this.currentStep + 1;
      this.setIsNextButtonDisabled(this.isFormValid);
    },
    saveAndGoToPreview() {
      this.save(true);
    },
    save(goToPreview = false) {
      try {
        if (this.slotNumber) {
          this.updateProfile(goToPreview);
        } else {
          this.createProfile(goToPreview);
        }
      } catch (error) {
        console.error('Wystąpił błąd:', error);
        this.isError = true;
        this.errorMessage = 'Wystapił błąd. Spróbuj ponownie.';
      }
    },
    createProfile(goToPreview) {
      axiosInstance.post(`${this.apiUrl}mem-profile/`, JSON.stringify(this.memorialProfile), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.authStore.userToken}`
        }
      }).then((response) => {
        if (!goToPreview) {
          return;
        }

        this.goToMemorialProfileView(response.data.slot_number);
      });
    },
    updateProfile(goToPreview) {
      if (this.memorialProfile.slot_number) {
        axiosInstance.put(
            `${this.apiUrl}mem-profile/${this.memorialProfile.slot_number}`,
            JSON.stringify(this.memorialProfile),
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.authStore.userToken}`
              }
            }
        ).then(() => {
          if (goToPreview) {
            this.goToMemorialProfileView(this.memorialProfile.slot_number);
          }
        });
      }
    },
    goToMemorialProfileView(slotNumber) {
      if (slotNumber) {
        this.$router.push({name: 'memorial-profile-view', params: {slotNumber}});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-breadcrumbs {
  padding: 8px 0 !important;
  color: #075985 !important;

  .v-breadcrumbs-item {
    padding: 0;
    font-size: 0.875rem;
    font-weight: 400 !important;
    line-height: 150%;
    letter-spacing: 0.1px;
  }

  .v-breadcrumbs-item--disabled {
    font-weight: 600 !important;
    color: #075985 !important;
    opacity: 1;
  }

  .v-breadcrumbs-divider {
    padding: 0 4px !important;
  }
}

.memorial-profile-creation-page {
  width: 100%;
  margin: 32px auto;
  padding: 20px;
  border-radius: 8px;

  @media (min-width: 768px) {
    width: 100%;
    max-width: 600px;
  }
}

.steps-footer {
  display: flex;
  justify-content: space-between;
}
</style>
